/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module {
  height: calc(100vh - 60px);
}
.Module .module-header {
  background-color: #272e34;
  min-height: 62px;
  clear: both;
}
.Module .module-header .wrap {
  display: inline-flex;
  max-width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
}
.Module .module-header .module-header-info {
  height: 62px;
}
.Module .module-header .module-header-info .module-header-banner {
  font-size: x-large;
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title {
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title i,
.Module .module-header .module-header-info .module-header-title a {
  color: #0df;
}
.Module .module-header .module-header-info .module-header-title a {
  font-size: 1rem;
}
.Module .module-header .module-header-info .module-header-title i {
  transform: scale(0.9);
}
.Module .module-header .header-selected {
  border-radius: 0;
}
.Module .module-header .view-exit {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}
.Module .module-header .view-title {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}
.Module .module-header .view-title .material-icons {
  color: #fff;
  font-size: 24px;
  padding-left: 20px;
}
.Module .module-header .header-bar {
  float: left;
  margin-left: 10px;
}
.Module .module-header .header-bar a .btn {
  margin-right: 10px;
}
.Module .module-header .header-bar .form-inline {
  float: left;
  padding-top: 11px;
  font-size: 1rem;
}
.Module .module-header .header-bar .form-inline .input-group {
  margin-right: 20px;
}
.Module .module-header .header-bar .form-inline input {
  display: inline-block;
}
.Module .module-header .header-bar .form-inline label {
  padding-right: 5px;
  text-transform: capitalize;
}
.Module .module-header .header-bar .form-inline ::placeholder {
  color: #dae0e7;
}
.Module .module-header .secondary-header-toolbar {
  float: right;
  margin-right: 10px;
}
.Module .module-header .header-menu {
  background-color: #272e34;
}
.Module .module-header .header-selector {
  padding: 10px;
}
.Module .module-header .header-selector label {
  padding-top: 10px;
  padding-right: 10px;
  margin: 0;
}
.Module .module-header .header-selector .material-icons {
  padding-top: 5px;
}
.Module .module-header .header-selector .dropdown-menu-dark button {
  color: #aaaeb3;
  background-color: #272e34;
}
.Module .module-header .header-disabled {
  visibility: hidden;
}
.Module .module-header .btn-toolbar .header-button-group .material-icons {
  position: relative;
  top: 2px;
  color: #aaaeb3;
  padding-right: 5px;
  font-size: 16px;
}
.Module .module-header .btn-toolbar .header-button-group .selected .material-icons {
  color: #4bb3d8;
}
.Module .module-header .btn-toolbar .btn-group {
  margin-right: 10px;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary {
  outline: none;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary:focus {
  outline: none;
  box-shadow: none;
}
.Module .module-header .btn-toolbar .btn-group .btn {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-header .btn-toolbar .date-range-group .selected .material-icons {
  color: #fff;
}
.Module .module-content {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #272e34;
  height: calc(100% - 62px);
}
.Module .module-content .header-id {
  font-size: small;
  color: #aaaeb3;
  padding-left: 10px;
}
.Module .module-content .content-view {
  height: 100%;
}
.Module .module-content .force {
  position: fixed;
  bottom: 0;
  margin-right: 20px;
  width: calc(100vw - 190px - 10px);
}
.Module .module-content .content-view-controls {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
  padding-left: 0;
  height: 50px;
  background-color: #272e34;
}
.Module .module-content .content-view-controls a .btn:last-child {
  margin-right: 10px;
}
.Module .module-content .content-view-controls .header-button-group .btn {
  margin: 0;
}
.Module .module-content .content-view-controls label {
  margin-top: 5px;
  margin-right: 5px;
}
.Module .module-content .content-view-controls form {
  margin-right: 10px;
}
.Module .module-content .content-view-controls form .form-check-label-color {
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-top: 0;
}
.Module .module-content .content-view-controls .form-check-inline {
  margin-right: 0;
}
.Module .module-content .search-bar {
  position: fixed;
  width: calc(100% - 190px);
  padding-right: 20px;
}
.Module .module-content .search-bar .input-group {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group .input-group-text {
  background-color: #272e34;
  color: #fff;
}
.Module .module-content .search-bar .input-group button,
.Module .module-content .search-bar .input-group input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
}
.Module .module-content .search-bar .input-group-sm {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group-sm .input-group-text {
  background-color: #272e34;
  color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .input-group-sm button,
.Module .module-content .search-bar .input-group-sm input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .form-check-label {
  padding: 0 5px;
  margin-right: 10px;
  border-radius: 5px;
}
.Module .module-content .content {
  float: left;
  width: calc(100% - 330px - 10px - 2px);
  height: 100%;
  background-color: #353c44;
}
.Module .module-content .content .content-with-title {
  height: 100%;
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main {
  height: calc(100% - 300px);
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main-full {
  height: 100%;
}
.Module .module-content .content .content-footer {
  height: 300px;
  width: 100%;
}
.Module .module-content .content .ok {
  background-color: #4caf50;
}
.Module .module-content .content .warning {
  background-color: orange;
}
.Module .module-content .content .error {
  background-color: #e22006;
}
.Module .module-content .content .no-status {
  background-color: #272e34;
}
.Module .module-content .content .no-match {
  text-align: center;
}
.Module .module-content .content .content-title {
  text-align: center;
  padding: 10px;
  margin-bottom: 0;
}
.Module .module-content .content .content-subtitle {
  text-align: center;
  padding: 10px;
  margin: 0;
}
.Module .module-content .content .site-content {
  padding-bottom: 50px;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.ok {
  color: #4caf50;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.warning {
  color: orange;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.error {
  color: #e22006;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.no-status {
  color: #fff;
}
.Module .module-content .content .site-content td.ranged {
  background-color: #272e34;
}
.Module .module-content .content .system-content {
  clear: both;
}
.Module .module-content .content table {
  width: 100%;
  border-collapse: separate;
  /* Don't collapse as this makes sticky headers see through */
  border-spacing: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content table th {
  top: 0;
  border-bottom: 2px solid #272e34;
  background-color: #353c44;
  z-index: 1;
  color: #4bb3d8;
  text-align: left;
}
.Module .module-content .content table th.ranged {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content table td {
  border: solid 1px #272e34;
}
.Module .module-content .content table td a {
  color: #fff;
}
.Module .module-content .content table td .btn-link {
  padding: 0;
  padding-left: 5px;
}
.Module .module-content .content table td .btn-link .material-icons {
  color: #4bb3d8;
}
.Module .module-content .content table td.danger {
  background-color: #e22006;
}
.Module .module-content .content table td.ranged {
  background-color: #353c44;
}
.Module .module-content .content table th.edit {
  width: 20px;
}
.Module .module-content .content .content-map {
  height: 100%;
  width: 100%;
}
.Module .module-content .content nav {
  background-color: #272e34;
  z-index: 100;
  color: #fff;
}
.Module .module-content .content nav .react-contextmenu-item {
  color: #fff;
}
.Module .module-content .content nav .ok > .react-contextmenu-item {
  background-color: #4caf50;
}
.Module .module-content .content nav .warning > .react-contextmenu-item {
  background-color: orange;
}
.Module .module-content .content nav .error > .react-contextmenu-item {
  background-color: #e22006;
}
.Module .module-content .content nav .no-nodes > .react-contextmenu-item {
  padding: 0 10px;
}
.Module .module-content .content .content-graph-menu {
  text-align: center;
  background-color: #272e34;
  clear: both;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-left {
  font-weight: bold;
  float: left;
  margin-left: 10px;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-right {
  float: right;
  margin-right: 10px;
}
.Module .module-content .content .content-graph-menu .dropdown {
  display: inline;
  z-index: 10;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-menu {
  background-color: #1f1f1f;
}
.Module .module-content .content .content-graph-menu .dropdown button {
  color: #fff;
  background-color: #272e34;
  border: none;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-item:hover {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-toggle {
  color: #fff;
  box-shadow: none;
}
.Module .module-content .content .content-graph-menu .dropdown .selected {
  color: #4bb3d8;
}
.Module .module-content .content .content-graph-empty {
  text-align: center;
  font-size: large;
  padding-top: 100px;
}
.Module .module-content .content .content-graph,
.Module .module-content .content .content-source-graph {
  width: 100%;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper {
  pointer-events: auto;
  margin-left: -5px;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip {
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list {
  max-height: 165px;
}
.Module .module-content .content .content-graph {
  height: calc(300px - 30px);
}
.Module .module-content .content .content-source-graph {
  height: calc(300px + 230px);
}
.Module .module-content .content-full {
  width: 100%;
  overflow-y: auto;
}
.Module .module-content .content-full-with-title {
  width: 100%;
  height: calc(100% - 66px);
  overflow-y: auto;
}
.Module .module-content .close-button {
  top: 0;
  font-size: 48px;
}
.Module .module-content .item-details {
  height: 100%;
}
.Module .module-content .item-details .item-details-title {
  padding: 10px 20px;
  font-size: large;
}
.Module .module-content .item-details .item-details-title h2 {
  padding: 0;
}
.Module .module-content .item-details .item-details-title h2 .right-title {
  float: right;
  font-size: medium;
  padding-top: 14px;
}
.Module .module-content .item-details .item-create-title {
  background-color: #4bb3d8;
}
.Module .module-content .item-details .item-details-content {
  overflow: auto;
  height: calc(100% - 66px);
  background-color: #272e34;
  padding: 20px;
}
.Module .module-content .item-details .item-details-content label {
  color: #4bb3d8;
  margin-right: 5px;
}
.Module .module-content .item-details .item-details-content .ecogy-form label {
  margin-right: 0;
}
.Module .module-content .item-details .item-details-content .type {
  text-transform: capitalize;
}
.Module .module-content .item-details .item-details-content hr {
  margin: 2px;
  border-top-color: #dae0e7;
}
.Module .module-content .item-details .item-details-content .remove-button {
  margin-bottom: 20px;
}
.Module .module-content .item-details .item-details-content form > .form-group fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content form > .form-group > div > div > fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content .right-column {
  float: right;
  clear: both;
  padding-right: 0;
  padding-left: 10px;
}
.Module .module-content .content-sidebar {
  margin-left: 10px;
  float: left;
  width: 330px;
  height: 100%;
  overflow: auto;
}
.Module .module-content .content-sidebar .sidebar-section {
  margin-top: 10px;
  background-color: #353c44;
  width: 100%;
  padding: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title {
  width: 100%;
  color: #fff;
  text-transform: uppercase;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-subtitle {
  color: #aaaeb3;
  float: right;
  margin: 0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-range {
  color: #aaaeb3;
  margin: 0;
  font-size: small;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content {
  width: 100%;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-heading {
  font-size: xx-large;
  font-weight: bold;
  color: #4bb3d8;
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .positive {
  color: #00c4a0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .negative {
  color: #ff9b91;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text {
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text .material-icons {
  float: right;
  padding-top: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-reading {
  color: #4bb3d8;
  font-size: x-large;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .warning {
  color: orange;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-error {
  color: #4bb3d8;
  font-size: large;
  text-align: center;
  padding: 10px 0;
}
.Module .module-content .content-sidebar :first-child {
  margin: 0;
}
.Module .module-content .content-sidebar .date-range {
  color: #aaaeb3;
}
.Module .module-content .content-sidebar .date-range .start-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .end-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .date-label {
  width: 50px;
}
.Module .module-content .recharts-tooltip-cursor {
  fill-opacity: 25%;
}
.Module .module-content .content-with-controls {
  height: calc(100% - 50px);
}
.Module .collapsed {
  height: calc(100% - 62px - 41px);
}

.App-portfolio .module-header {
  background-color: #4bb3d8;
  min-height: 61px;
  border-bottom: 1px solid black;
}
.App-portfolio .module-content {
  margin-top: 10px;
  height: calc(100% - 62px - 10px);
}
.App-portfolio .collapsed {
  height: calc(100% - 62px - 41px - 10px);
}

.area-graph-tooltip {
  background: #fff;
  padding: 10px;
  color: #272e34;
  text-transform: capitalize;
}
.area-graph-tooltip p {
  margin: 0;
}

.econode-tooltip .area-graph-tooltip,
.ecogy-tooltip .area-graph-tooltip {
  text-transform: none;
}

.modal {
  color: #000;
}
.modal .error {
  color: #e22006;
}

.custom-range-button {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-range-name > * {
  float: left;
}
.custom-range-name .range .range-start {
  font-size: small;
}
.custom-range-name .range .range-end {
  font-size: small;
}
.custom-range-name .range .range-divider {
  display: none;
}

.date-range-menu {
  width: 700px;
  height: 370px;
  left: auto;
  right: 0;
}
.date-range-menu .custom-date-range {
  float: left;
  width: 510px;
}
.date-range-menu .custom-date-range .custom-date {
  float: left;
  width: 250px;
  margin-left: 5px;
  height: 310px;
}
.date-range-menu .precanned-date-ranges {
  float: left;
  margin-left: 10px;
  height: 100%;
  width: 170px;
  background-color: #353c44;
  border-radius: 5px;
  padding: 10px;
}
.date-range-menu .precanned-date-ranges .dropdown-item {
  padding: 0.25rem;
  border: none;
}
.date-range-menu .precanned-date-ranges .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.date-range-menu .date-range-buttons {
  clear: both;
}
.date-range-menu .date-range-buttons .date-range-button {
  border-radius: 10px;
  float: right;
}
.date-range-menu .date-range-message {
  width: 100%;
  color: #4bb3d8;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-message .material-icons {
  color: #4bb3d8;
}
.date-range-menu .date-range-warning {
  width: 100%;
  color: #e22006;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-warning .material-icons {
  color: #e22006;
}

.battery-view-header {
  text-align: center;
}

.battery-box {
  height: 150px;
  width: 200px;
  background-color: rgb(29, 33, 39);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 75px 75px;
  color: grey;
}

.battery-container {
  text-align: center;
  align-items: center;
  justify-items: center;
  height: 350px;
  width: 250px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 80px 230px;
  grid-template-rows: 80px 150px 80px;
  color: rgb(207, 205, 205);
}
.battery-container p {
  font-size: medium;
}
.battery-container .state-of-health {
  margin-top: 8px;
  margin-bottom: 40px;
  background-color: #193138;
  border-radius: 5px;
  font-size: small;
}

.graph-tail-end {
  height: 8px;
  width: 8px;
  background-color: #00C49F;
  bottom: 180px;
  left: 0px;
  position: absolute;
  border-radius: 5px;
  z-index: 100;
}

.battery-cell {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #1c3841;
  height: 100px;
  width: 70px;
  position: relative;
}
.battery-cell .logo {
  position: relative;
  top: 40px;
  left: -14px;
  max-width: 100px;
  filter: opacity(0.6) drop-shadow(0 0 0 rgb(0, 0, 0));
  transform: rotate(270deg);
}
.battery-cell .charge-bar {
  margin: 5px;
  position: absolute;
  width: 60px;
  bottom: 0px;
  background-color: #4caf50;
}
.battery-cell .empty {
  visibility: hidden;
}
.battery-cell .low {
  background-color: #e22006;
}
.battery-cell .high {
  background-color: #4caf50;
}
.battery-cell .charge-bar-wrapper {
  position: absolute;
  height: 90px;
  width: 60px;
  bottom: 0px;
}
.battery-cell .charge-bar-top {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  top: -10px;
  box-shadow: inset 0px -6px 12px 0px hsla(0, 0%, 0%, 0.2);
}
.battery-cell .charge-bar-bottom {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  bottom: -10px;
}
.battery-cell .battery-cell-top {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -10px;
  background-color: #193138;
  z-index: 100;
}
.battery-cell .battery-cell-top2 {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -5px;
  background-color: #1c3841;
  z-index: 99;
}
.battery-cell .battery-cell-bottom {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  bottom: -10px;
  background-color: #1c3841;
}

[class|=confetti] {
  position: absolute;
  z-index: 3000;
}

.papers-origin {
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
}

.confetti-0 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(321.6524308245deg);
  animation: drop-0 3.7098860995s 0.0025320433s 3;
}

@keyframes drop-0 {
  25% {
    top: -21vh;
    left: -10vw;
    opacity: 1.13306872;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-1 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(147.8132259439deg);
  animation: drop-1 3.7665001694s 0.4964135672s 3;
}

@keyframes drop-1 {
  25% {
    top: -24vh;
    left: -12vw;
    opacity: 0.9434180881;
  }
  100% {
    top: 50vh;
    left: -19vw;
    opacity: 0;
  }
}
.confetti-2 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(220.6175408098deg);
  animation: drop-2 3.3995773373s 0.8977190403s 3;
}

@keyframes drop-2 {
  25% {
    top: -20vh;
    left: 7vw;
    opacity: 0.5210965223;
  }
  100% {
    top: 50vh;
    left: 2vw;
    opacity: 0;
  }
}
.confetti-3 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(71.1502380642deg);
  animation: drop-3 3.2226811637s 0.7450937876s 3;
}

@keyframes drop-3 {
  25% {
    top: -20vh;
    left: 12vw;
    opacity: 1.0225765263;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-4 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(348.1276066831deg);
  animation: drop-4 3.2479017923s 0.0929494943s 3;
}

@keyframes drop-4 {
  25% {
    top: -13vh;
    left: -10vw;
    opacity: 0.65935594;
  }
  100% {
    top: 50vh;
    left: -12vw;
    opacity: 0;
  }
}
.confetti-5 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(197.6006889992deg);
  animation: drop-5 3.2009227549s 0.5075142672s 3;
}

@keyframes drop-5 {
  25% {
    top: -1vh;
    left: 9vw;
    opacity: 1.4583267236;
  }
  100% {
    top: 50vh;
    left: 22vw;
    opacity: 0;
  }
}
.confetti-6 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(45.1124620435deg);
  animation: drop-6 3.8898718929s 0.0382300364s 3;
}

@keyframes drop-6 {
  25% {
    top: -15vh;
    left: -7vw;
    opacity: 0.7920298057;
  }
  100% {
    top: 50vh;
    left: -20vw;
    opacity: 0;
  }
}
.confetti-7 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(167.1452981141deg);
  animation: drop-7 3.4807737344s 0.90664314s 3;
}

@keyframes drop-7 {
  25% {
    top: -9vh;
    left: 3vw;
    opacity: 0.9104243103;
  }
  100% {
    top: 50vh;
    left: 13vw;
    opacity: 0;
  }
}
.confetti-8 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(258.6646969968deg);
  animation: drop-8 3.607533626s 0.1029286637s 3;
}

@keyframes drop-8 {
  25% {
    top: -4vh;
    left: 1vw;
    opacity: 0.5073204815;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-9 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(103.5625736721deg);
  animation: drop-9 3.7659737248s 0.6660958711s 3;
}

@keyframes drop-9 {
  25% {
    top: -18vh;
    left: -3vw;
    opacity: 1.3988870617;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-10 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(263.8375989342deg);
  animation: drop-10 3.4662159704s 0.5966045058s 3;
}

@keyframes drop-10 {
  25% {
    top: -25vh;
    left: -1vw;
    opacity: 1.1117692041;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-11 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(282.4825298961deg);
  animation: drop-11 3.2755534935s 0.0662915253s 3;
}

@keyframes drop-11 {
  25% {
    top: -13vh;
    left: 8vw;
    opacity: 1.29761154;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-12 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(287.8671491731deg);
  animation: drop-12 3.6942637002s 0.9318682642s 3;
}

@keyframes drop-12 {
  25% {
    top: -21vh;
    left: -12vw;
    opacity: 0.642225136;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-13 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(163.9695219629deg);
  animation: drop-13 3.5439752694s 0.167662839s 3;
}

@keyframes drop-13 {
  25% {
    top: -17vh;
    left: 13vw;
    opacity: 1.1695108312;
  }
  100% {
    top: 50vh;
    left: 20vw;
    opacity: 0;
  }
}
.confetti-14 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(250.5227856645deg);
  animation: drop-14 3.4596753512s 0.5748556054s 3;
}

@keyframes drop-14 {
  25% {
    top: -11vh;
    left: -6vw;
    opacity: 1.3038521926;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-15 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(254.4955595237deg);
  animation: drop-15 3.6394008881s 0.1407758237s 3;
}

@keyframes drop-15 {
  25% {
    top: -23vh;
    left: -10vw;
    opacity: 0.8108776215;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-16 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(34.5984291305deg);
  animation: drop-16 3.3825498234s 0.0379893113s 3;
}

@keyframes drop-16 {
  25% {
    top: -15vh;
    left: 9vw;
    opacity: 0.8590534972;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-17 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(208.6557266399deg);
  animation: drop-17 3.9001922986s 0.8513475265s 3;
}

@keyframes drop-17 {
  25% {
    top: -13vh;
    left: 15vw;
    opacity: 0.9404768897;
  }
  100% {
    top: 50vh;
    left: 7vw;
    opacity: 0;
  }
}
.confetti-18 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(116.8831789858deg);
  animation: drop-18 3.8611766713s 0.1126840802s 3;
}

@keyframes drop-18 {
  25% {
    top: -4vh;
    left: 8vw;
    opacity: 0.8968872454;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-19 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(228.6823870856deg);
  animation: drop-19 3.2458645853s 0.0679723681s 3;
}

@keyframes drop-19 {
  25% {
    top: -15vh;
    left: 7vw;
    opacity: 1.455877884;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-20 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(137.5101891568deg);
  animation: drop-20 3.3492099225s 0.4469441524s 3;
}

@keyframes drop-20 {
  25% {
    top: -8vh;
    left: 13vw;
    opacity: 1.1707844843;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-21 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(80.7647902025deg);
  animation: drop-21 3.6582264818s 0.1095526879s 3;
}

@keyframes drop-21 {
  25% {
    top: -25vh;
    left: -13vw;
    opacity: 0.7180943207;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-22 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(92.8515840598deg);
  animation: drop-22 3.2931311047s 0.5266727879s 3;
}

@keyframes drop-22 {
  25% {
    top: -18vh;
    left: -9vw;
    opacity: 1.2740586123;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-23 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(65.2562059698deg);
  animation: drop-23 3.4921077605s 0.2370269816s 3;
}

@keyframes drop-23 {
  25% {
    top: -22vh;
    left: -4vw;
    opacity: 1.4224957731;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-24 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(125.4943749368deg);
  animation: drop-24 3.0362596997s 0.6407574362s 3;
}

@keyframes drop-24 {
  25% {
    top: -9vh;
    left: 7vw;
    opacity: 1.0161392011;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-25 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(276.1456649819deg);
  animation: drop-25 3.4087739825s 0.3520375448s 3;
}

@keyframes drop-25 {
  25% {
    top: -21vh;
    left: 1vw;
    opacity: 1.0825231975;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-26 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(355.6272926456deg);
  animation: drop-26 3.0645371073s 0.3337766794s 3;
}

@keyframes drop-26 {
  25% {
    top: -21vh;
    left: 14vw;
    opacity: 1.0376108476;
  }
  100% {
    top: 50vh;
    left: 26vw;
    opacity: 0;
  }
}
.confetti-27 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(166.0230215353deg);
  animation: drop-27 3.2485823292s 0.7033117999s 3;
}

@keyframes drop-27 {
  25% {
    top: -17vh;
    left: 10vw;
    opacity: 0.9097389066;
  }
  100% {
    top: 50vh;
    left: -2vw;
    opacity: 0;
  }
}
.confetti-28 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(160.4583221587deg);
  animation: drop-28 3.4902532389s 0.6239892916s 3;
}

@keyframes drop-28 {
  25% {
    top: -17vh;
    left: 1vw;
    opacity: 0.6116974612;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-29 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(8.7220610732deg);
  animation: drop-29 3.9237157575s 0.9724971143s 3;
}

@keyframes drop-29 {
  25% {
    top: -1vh;
    left: 6vw;
    opacity: 1.1018303863;
  }
  100% {
    top: 50vh;
    left: 17vw;
    opacity: 0;
  }
}
.confetti-30 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(133.5395432604deg);
  animation: drop-30 3.7921069178s 0.2167663143s 3;
}

@keyframes drop-30 {
  25% {
    top: -17vh;
    left: 13vw;
    opacity: 1.1451600645;
  }
  100% {
    top: 50vh;
    left: 24vw;
    opacity: 0;
  }
}
.confetti-31 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(118.5251397958deg);
  animation: drop-31 3.7912312433s 0.2417224213s 3;
}

@keyframes drop-31 {
  25% {
    top: -18vh;
    left: -5vw;
    opacity: 0.9938783057;
  }
  100% {
    top: 50vh;
    left: -12vw;
    opacity: 0;
  }
}
.confetti-32 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(305.4973711104deg);
  animation: drop-32 3.9130340513s 0.3839646963s 3;
}

@keyframes drop-32 {
  25% {
    top: -5vh;
    left: 11vw;
    opacity: 0.649404501;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-33 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(22.9871223366deg);
  animation: drop-33 3.5943829836s 0.8702588485s 3;
}

@keyframes drop-33 {
  25% {
    top: -18vh;
    left: -10vw;
    opacity: 1.104478724;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-34 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(289.7041110538deg);
  animation: drop-34 3.1796477516s 0.9113892367s 3;
}

@keyframes drop-34 {
  25% {
    top: -21vh;
    left: 10vw;
    opacity: 1.3014987516;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-35 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(137.1020924666deg);
  animation: drop-35 3.8444628207s 0.7821834616s 3;
}

@keyframes drop-35 {
  25% {
    top: -18vh;
    left: 7vw;
    opacity: 1.3467212733;
  }
  100% {
    top: 50vh;
    left: 17vw;
    opacity: 0;
  }
}
.confetti-36 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(321.0228843298deg);
  animation: drop-36 3.2030300166s 0.7297436508s 3;
}

@keyframes drop-36 {
  25% {
    top: -17vh;
    left: -11vw;
    opacity: 0.6453840274;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-37 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(151.3425521364deg);
  animation: drop-37 3.3731993977s 0.2779293597s 3;
}

@keyframes drop-37 {
  25% {
    top: -9vh;
    left: -1vw;
    opacity: 0.7568308581;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-38 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(209.8630021754deg);
  animation: drop-38 3.5131442393s 0.8552998065s 3;
}

@keyframes drop-38 {
  25% {
    top: -8vh;
    left: 1vw;
    opacity: 1.0504124493;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-39 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(64.1733637143deg);
  animation: drop-39 3.628474933s 0.2126814999s 3;
}

@keyframes drop-39 {
  25% {
    top: -6vh;
    left: 15vw;
    opacity: 0.7373335321;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-40 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(287.1283869992deg);
  animation: drop-40 3.2316410524s 0.7189311159s 3;
}

@keyframes drop-40 {
  25% {
    top: -11vh;
    left: -5vw;
    opacity: 0.6933523507;
  }
  100% {
    top: 50vh;
    left: -12vw;
    opacity: 0;
  }
}
.confetti-41 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(198.9939254238deg);
  animation: drop-41 3.0649588547s 0.1107832796s 3;
}

@keyframes drop-41 {
  25% {
    top: -11vh;
    left: -3vw;
    opacity: 1.0552828007;
  }
  100% {
    top: 50vh;
    left: -4vw;
    opacity: 0;
  }
}
.confetti-42 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(85.020803508deg);
  animation: drop-42 3.7567942535s 0.7249212583s 3;
}

@keyframes drop-42 {
  25% {
    top: -6vh;
    left: 6vw;
    opacity: 0.7799244843;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-43 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(63.8937347619deg);
  animation: drop-43 3.2159942831s 0.2759241297s 3;
}

@keyframes drop-43 {
  25% {
    top: -6vh;
    left: -12vw;
    opacity: 1.04676135;
  }
  100% {
    top: 50vh;
    left: -9vw;
    opacity: 0;
  }
}
.confetti-44 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(112.2428478327deg);
  animation: drop-44 3.3757755018s 0.1807887562s 3;
}

@keyframes drop-44 {
  25% {
    top: -20vh;
    left: 9vw;
    opacity: 1.1016939359;
  }
  100% {
    top: 50vh;
    left: 10vw;
    opacity: 0;
  }
}
.confetti-45 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(42.7445295756deg);
  animation: drop-45 3.0366035046s 0.6637838775s 3;
}

@keyframes drop-45 {
  25% {
    top: -25vh;
    left: -13vw;
    opacity: 0.7741706028;
  }
  100% {
    top: 50vh;
    left: -23vw;
    opacity: 0;
  }
}
.confetti-46 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(289.4437278487deg);
  animation: drop-46 3.8118176181s 0.512512148s 3;
}

@keyframes drop-46 {
  25% {
    top: -7vh;
    left: -1vw;
    opacity: 1.252129312;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-47 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(335.8092007417deg);
  animation: drop-47 3.1313937179s 0.4498457593s 3;
}

@keyframes drop-47 {
  25% {
    top: -21vh;
    left: 14vw;
    opacity: 1.2616000047;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-48 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(237.4745802505deg);
  animation: drop-48 3.3184768507s 0.2893274665s 3;
}

@keyframes drop-48 {
  25% {
    top: -23vh;
    left: 4vw;
    opacity: 0.7540863781;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-49 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(288.7817390492deg);
  animation: drop-49 3.0504520215s 0.1836696783s 3;
}

@keyframes drop-49 {
  25% {
    top: -15vh;
    left: 2vw;
    opacity: 1.1236900702;
  }
  100% {
    top: 50vh;
    left: 13vw;
    opacity: 0;
  }
}
.confetti-50 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(4.8563917711deg);
  animation: drop-50 3.5871745278s 0.6026856971s 3;
}

@keyframes drop-50 {
  25% {
    top: -4vh;
    left: -8vw;
    opacity: 1.1896837488;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-51 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(239.2672771076deg);
  animation: drop-51 3.8026986139s 0.6374358854s 3;
}

@keyframes drop-51 {
  25% {
    top: -22vh;
    left: 2vw;
    opacity: 1.2068794935;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-52 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(170.2708028161deg);
  animation: drop-52 3.7625429813s 0.1707233868s 3;
}

@keyframes drop-52 {
  25% {
    top: -1vh;
    left: 7vw;
    opacity: 1.4517414933;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-53 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(140.0926110877deg);
  animation: drop-53 3.8187983675s 0.8447309035s 3;
}

@keyframes drop-53 {
  25% {
    top: -18vh;
    left: 9vw;
    opacity: 1.4235869081;
  }
  100% {
    top: 50vh;
    left: -5vw;
    opacity: 0;
  }
}
.confetti-54 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(68.1752177559deg);
  animation: drop-54 3.8779934573s 0.9045334119s 3;
}

@keyframes drop-54 {
  25% {
    top: -22vh;
    left: -12vw;
    opacity: 1.1844904874;
  }
  100% {
    top: 50vh;
    left: -2vw;
    opacity: 0;
  }
}
.confetti-55 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(274.9932445414deg);
  animation: drop-55 3.575134665s 0.8213203563s 3;
}

@keyframes drop-55 {
  25% {
    top: -2vh;
    left: 4vw;
    opacity: 1.1347395271;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-56 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(54.9997941027deg);
  animation: drop-56 3.1478718665s 0.4512224725s 3;
}

@keyframes drop-56 {
  25% {
    top: -7vh;
    left: -7vw;
    opacity: 1.3205413602;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-57 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(227.4812071492deg);
  animation: drop-57 3.2807051549s 0.9632294255s 3;
}

@keyframes drop-57 {
  25% {
    top: -1vh;
    left: -7vw;
    opacity: 0.8909857424;
  }
  100% {
    top: 50vh;
    left: -21vw;
    opacity: 0;
  }
}
.confetti-58 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(210.3928231213deg);
  animation: drop-58 3.3690857313s 0.0962501556s 3;
}

@keyframes drop-58 {
  25% {
    top: -4vh;
    left: 4vw;
    opacity: 1.0880348872;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-59 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(160.2105464572deg);
  animation: drop-59 3.7147815093s 0.7281875003s 3;
}

@keyframes drop-59 {
  25% {
    top: -15vh;
    left: -14vw;
    opacity: 0.7981469438;
  }
  100% {
    top: 50vh;
    left: -24vw;
    opacity: 0;
  }
}
.confetti-60 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(319.7155237164deg);
  animation: drop-60 3.1105898057s 0.2475787105s 3;
}

@keyframes drop-60 {
  25% {
    top: -25vh;
    left: -8vw;
    opacity: 0.5037876094;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-61 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(70.2926477267deg);
  animation: drop-61 3.8952129968s 0.1931581374s 3;
}

@keyframes drop-61 {
  25% {
    top: -13vh;
    left: 6vw;
    opacity: 0.7827647335;
  }
  100% {
    top: 50vh;
    left: -4vw;
    opacity: 0;
  }
}
.confetti-62 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(198.7926914858deg);
  animation: drop-62 3.45726578s 0.9639630386s 3;
}

@keyframes drop-62 {
  25% {
    top: -10vh;
    left: 13vw;
    opacity: 0.8585969118;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-63 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(99.0270809472deg);
  animation: drop-63 3.7050219575s 0.3811162205s 3;
}

@keyframes drop-63 {
  25% {
    top: -2vh;
    left: -7vw;
    opacity: 1.24778234;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-64 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(228.0282832622deg);
  animation: drop-64 3.7341025868s 0.2231955682s 3;
}

@keyframes drop-64 {
  25% {
    top: -3vh;
    left: -7vw;
    opacity: 0.7681737088;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-65 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(61.4225350333deg);
  animation: drop-65 3.2067567963s 0.6650485643s 3;
}

@keyframes drop-65 {
  25% {
    top: -15vh;
    left: -9vw;
    opacity: 1.0674408931;
  }
  100% {
    top: 50vh;
    left: -15vw;
    opacity: 0;
  }
}
.confetti-66 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(304.9299873847deg);
  animation: drop-66 3.1779202094s 0.8257370173s 3;
}

@keyframes drop-66 {
  25% {
    top: -11vh;
    left: 5vw;
    opacity: 1.3760607411;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-67 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(274.0539415162deg);
  animation: drop-67 3.2162622365s 0.7045842716s 3;
}

@keyframes drop-67 {
  25% {
    top: -5vh;
    left: -3vw;
    opacity: 0.6262057384;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-68 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(62.9178957604deg);
  animation: drop-68 3.4498700004s 0.5769133326s 3;
}

@keyframes drop-68 {
  25% {
    top: -24vh;
    left: 11vw;
    opacity: 1.1375846305;
  }
  100% {
    top: 50vh;
    left: -2vw;
    opacity: 0;
  }
}
.confetti-69 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(269.9251684024deg);
  animation: drop-69 3.9215452481s 0.0245772984s 3;
}

@keyframes drop-69 {
  25% {
    top: -11vh;
    left: 1vw;
    opacity: 1.4322911526;
  }
  100% {
    top: 50vh;
    left: 16vw;
    opacity: 0;
  }
}
.confetti-70 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(210.3954964452deg);
  animation: drop-70 3.8473464507s 0.838539673s 3;
}

@keyframes drop-70 {
  25% {
    top: -7vh;
    left: 15vw;
    opacity: 0.8080855548;
  }
  100% {
    top: 50vh;
    left: 27vw;
    opacity: 0;
  }
}
.confetti-71 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(217.795971961deg);
  animation: drop-71 3.0360633642s 0.5587697163s 3;
}

@keyframes drop-71 {
  25% {
    top: -16vh;
    left: 4vw;
    opacity: 1.1502217767;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-72 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(249.628431807deg);
  animation: drop-72 3.4309113456s 0.5328197888s 3;
}

@keyframes drop-72 {
  25% {
    top: -18vh;
    left: -2vw;
    opacity: 1.0080402286;
  }
  100% {
    top: 50vh;
    left: -16vw;
    opacity: 0;
  }
}
.confetti-73 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(182.0145118251deg);
  animation: drop-73 3.9595879089s 0.1603914936s 3;
}

@keyframes drop-73 {
  25% {
    top: -14vh;
    left: -8vw;
    opacity: 1.2495894505;
  }
  100% {
    top: 50vh;
    left: -17vw;
    opacity: 0;
  }
}
.confetti-74 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(158.8722135134deg);
  animation: drop-74 3.8277989188s 0.0908705836s 3;
}

@keyframes drop-74 {
  25% {
    top: -20vh;
    left: 15vw;
    opacity: 1.2823564965;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-75 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(349.7888564179deg);
  animation: drop-75 3.9156209401s 0.882805103s 3;
}

@keyframes drop-75 {
  25% {
    top: -11vh;
    left: 12vw;
    opacity: 0.7000988126;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}

